exports.globalUnits = {
    "stk.": { unit: "kus", factor: 1 },
    blatt: { unit: "kus", factor: 1 },
    paar: { unit: "kus", factor: 1 },
    stk: { unit: "kus", factor: 1 },
    st: { unit: "kus", factor: 1 },
    teebeutel: { unit: "kus", factor: 1 },
    tücher: { unit: "kus", factor: 1 },
    rollen: { unit: "kus", factor: 1 },
    tabs: { unit: "kus", factor: 1 },
    stück: { unit: "kus", factor: 1 },
    mm: { unit: "cm", factor: 0.1 },
    cm: { unit: "cm", factor: 1 },
    zentimeter: { unit: "cm", factor: 1 },
    m: { unit: "cm", factor: 100 },
    meter: { unit: "cm", factor: 100 },
    g: { unit: "g", factor: 1 },
    gr: { unit: "g", factor: 1 },
    gramm: { unit: "g", factor: 1 },
    dag: { unit: "g", factor: 10 },
    kg: { unit: "g", factor: 1000 },
    kilogramm: { unit: "g", factor: 1000 },
    ml: { unit: "ml", factor: 1 },
    milliliter: { unit: "ml", factor: 1 },
    dl: { unit: "ml", factor: 10 },
    cl: { unit: "ml", factor: 100 },
    l: { unit: "ml", factor: 1000 },
    lt: { unit: "ml", factor: 1000 },
    liter: { unit: "ml", factor: 1000 },
    wg: { unit: "wg", factor: 1 },
    ks: { unit: "kus", factor: 1 },
    rolí: { unit: "kus", factor: 1 },
    role: { unit: "kus", factor: 1 },
    rolky: { unit: "kus", factor: 1 },
    rl: { unit: "kus", factor: 1 },
    "m²": { unit: "m²", factor: 1 },
    "p.dáv": { unit: "p.dáv", factor: 1 },
    pd: { unit: "p.dáv", factor: 1 },
};
